<template>
  <div class="footer-container">

    <!-- Footer -->
    <div class="container footer-content" v-if="isShowFooter">
      <div class="md:grid justify-between gap-10 grid-cols-5">

        <template v-if="Array.isArray(footerMenu) && footerMenu.length > 0">
          <div class="sm:block hidden" v-for="i in footerMenu" :key="`ftm-${i.id}`" v-if="isDesktop">
            <ul role="list" class="text-left">
              <h3 class="text-base font-bold text-primary-500 mb-2 text-left tracking-wider uppercase"
                  v-if="getLocaleString(i.name)">
                {{ getLocaleString(i.name) }}
              </h3>

              <li v-for="item in i.children" :key="`ftmc-${item.id}`">
                <NuxtLinkLocale :to="getLocaleString(item.href, true)" class="text-base text-primary-500 hover:underline">
                  {{ getLocaleString(item.name) }}
                </NuxtLinkLocale>
              </li>
            </ul>
          </div>
          <div class="sm:hidden block" v-else>
            <TemplatesFooterMobileFooter/>
          </div>
        </template>

        <div class="max-w-full">

          <Newsletter class="w-full" />

          <div class="mt-2">
            <div class="flex flex-wrap space-x-1" v-if="footerConfig?.social_media_links && Array.isArray(footerConfig.social_media_links)">
              <a
                  :key="`nwm-${idx}`"
                  :href="getLocaleString(item.link)"
                  class="fill-primary-400 hover:opacity-80 rounded-full p-2"
                  v-for="(item,idx) in footerConfig.social_media_links"
              >
                <NuxtImg class="size-5 object-contain" :src="item.icon" :alt="getLocaleString(item.name)" />
              </a>
            </div>
          </div>

          <NuxtLinkLocale class="block" :href="etbis.url" target="_blank" v-if="etbis?.url && etbis?.image">
            <NuxtImg class="w-24" loading="lazy" :src="etbis.image"/>
          </NuxtLinkLocale>
        </div>
      </div>

      <div class="border-t mt-12">
        <div class="container footer-html-area lg:px-0"
             v-if="getLocaleString(footerConfig?.legal_notice_text)"
             v-html="getLocaleString(footerConfig?.legal_notice_text)">
        </div>

        <!-- Copyright -->
        <Copyright class="!px-0"/>
      </div>
    </div>


  </div>
</template>

<script setup>
const {locale} = useI18n()

const {isDesktop} = useDevice()
const {isWholesaleUser} = await useCustomer()
const {isShowFooter} = useHelper()

const storeConfig = useStoreConfig()
const { footerMenu, footerConfig, contactConfig, etbis } = storeToRefs(storeConfig)

const phoneNumber = computed(() => contactConfig.value?.phone ? contactConfig.value?.phone[locale.value] : "")
const supportEmail = computed(() => contactConfig.value?.email ? contactConfig.value?.email[locale.value] : "")
const workingDays = computed(() => contactConfig.value.workingDays)
const workingHours = computed(() => contactConfig.value.workingHours)

</script>

<style lang="scss">
.footer-container {
  @apply flex flex-col text-primary divide-y divide-lightgray bg-white border-t border-gray-200;
}

.footer-content {
  @apply pt-6 sm:pt-12 pb-2.5 px-4;
}

.footer-html-area {
  @apply text-sm py-4 text-primary;
}

.footer-newsletter-wrapper {
  @apply lg:min-w-full;
}
</style>